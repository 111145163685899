import React from 'react'
import ImagesGalleryGrid from './ImagesGalleryGrid'

const MediaPage = ()=>{
    
    return (
        <div>
            <ImagesGalleryGrid/>
        </div>
    )
}
export default MediaPage;