export const LANDING = '/';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';

export const MEDIA = '/media';
export const MEETOURTEAM = '/meetourteam';
export const ABOUT = '/about';
export const NEWS = '/news';
export const DOWNLOAD = '/subscribed'
export const FEEDBACK = '/feedback'
