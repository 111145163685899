import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Navigation from "../Navigation";
import LandingPage from "../Landing";
import SignInPage from "../SignIn";
import HomePage from "../Home";
import AccountPage from "../Account";
import MeetOurTeam from "../MeetOurTeam";
import Media from "../Media";
import News from "../News";
import Download from "../Download";

import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";

const App = () => (
  <Router>
    <div>
      <Navigation />

      <Route exact path={ROUTES.LANDING} component={LandingPage} />
      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route path={ROUTES.HOME} component={HomePage} />
      <Route path={ROUTES.ACCOUNT} component={AccountPage} />

      <Route path={ROUTES.MEETOURTEAM} component={MeetOurTeam} />
      <Route path={ROUTES.MEDIA} component={Media} />
      <Route path={ROUTES.NEWS} component={News} />
      <Route path={ROUTES.DOWNLOAD} component={Download} />
    </div>
  </Router>
);

export default withAuthentication(App);
