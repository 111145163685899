import React from "react";
import { Row, Col } from "react-bootstrap";
import { Container, Jumbotron } from "react-bootstrap";
import "../../index.css";

const Landing = () => {
  return (
    <Container className="App">
      <br />
      <Row>
        <Col md={7} sm={12}>
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              title="killsteel"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/6Ne_OcRsS78"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            {/* <iframe title="cchickin" width="560" height="315" src="https://www.youtube.com/embed/L8W2kywtGT8?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>   */}
          </div>
        </Col>
        <Col md={5} sm={12}>
          <Jumbotron>
            <h1>KillSteel</h1>
            <p>
              KillSteel is a demolition derby game where players can use various
              powerups and momentum to rack up points and score kills. Be the
              first to establish yourself as the kill leader with two playable
              maps and a multitude of cars. This is Killsteel!
              <br />
              Coming soon!!!
              <br />
              <button>
                <a href="https://store.steampowered.com/app/1269550/KillSteel/">
                  DOWNLOAD ON STEAM
                </a>
                {/* <a href="https://firebasestorage.googleapis.com/v0/b/chicken-53b8f.appspot.com/o/KillSteelMidSprint.zip?alt=media&token=a1dd58fe-0c4e-4811-b38f-f7e50af19b85">Download MidSprint version</a> */}
                {/* <a href="https://firebasestorage.googleapis.com/v0/b/chicken-53b8f.appspot.com/o/BuildSprint5New.zip?alt=media&token=00d628dc-bd73-46da-9e66-8351489357cf">Download Demo Version 5</a> */}
              </button>
            </p>
            <p>
              {/* <Nav>
                        <Link to={ROUTES.SIGN_IN}><Button>Download</Button></Link>
                    </Nav> */}
            </p>
          </Jumbotron>
        </Col>
      </Row>
      <br />

      {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdbfdfx4Ht7YpIinmc3ykdFL3gXwMJAQwwlSkArkeaRDtOybw/viewform" width="640" height="3147" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
    </Container>
  );
};

export default Landing;
