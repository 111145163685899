const MembersData = [
    {
        id: 1,
        name: 'Bridger Smith',
        email: 'Bridgermetz@gmail.com',
        role : 'Producer',
        website : '',
        about : 'N/A',
        img:"/images/members/blank.PNG"
    },
    {
        id: 2,
        name: 'Nick Steiner',
        email: 'nick.steiner3@gmail.com',
        role : 'Artist',
        website : '',
        about : 'N/A',
        img:"/images/members/neek.gif"
    },
    {
        id: 3,
        name: 'Wilson',
        email: 'wilsonpuliu@gmail.com',
        role : 'Artist',
        website : '',
        about : 'N/A',
        img:"https://media.giphy.com/media/yidUzHnBk32Um9aMMw/giphy.gif"
    },
    {
        id: 4,
        name: 'Phillip',
        email: 'phillipriskin@gmail.com',
        role : 'Engineer',
        website : '',
        about : 'N/A',
        img:"/images/members/philip.jfif"
    },
    {
        id: 5,
        name: 'Jonathan Dao',
        email: 'jonathan.dao@iechs.org',
        role : '3D Artist',
        website : '',
        about : 'N/AArtist',
        img:"/images/members/jon.jpg"
    },
    {
        id: 6,
        name: 'Garin',
        email: 'garinstrader@gmail.com',
        role : 'Engineer & Artist',
        website : '',
        about : 'N/A',
        img:"/images/members/garin.gif"
    },
    {
        id: 7,
        name: 'Berkeley',
        email: 'berkeley.bidwell@utah.edu',
        role : 'Engineer',
        website : '',
        about : 'N/A',
        img:"https://media.giphy.com/media/13GIgrGdslD9oQ/giphy.gif"
    },
    {
        id: 8,
        name: 'WI Issarakoat',
        email: 'weeravut.issarakoat@gmail.com',
        role : 'Engineer',
        website : 'issarakoat.github.io',
        about : 'N/A',
        img:"/images/chicken_devil.PNG"
    },
    {
        id: 9,
        name: 'Sam',
        email: 'mrhamuel@gmail.com',
        website : '',
        role : 'Artist',
        about : 'N/A',
        img:"/images/members/blank.PNG"
    },
    {
        id: 10,
        name: 'Colby James',
        email: 'Colbyjames85@gmail.com',
        role : 'Art Enthusiast',
        website : '',
        about : 'N/A',
        img:"/images/members/blank.PNG"
    },
    {
        id: 11,
        name: 'Shawn',
        email: 'iskalmev@gmail.com',
        role : 'Design',
        website : '',
        about : 'N/A',
        img:"/images/members/shawn.png"
    },
    {
        id: 12,
        name: 'Garrett',
        email: 'garrett.bryce.young@gmail.com',
        role : 'Design',
        website : '',
        about : 'N/A',
        img:"/images/members/blank.PNG"
    },
    {
        id: 13,
        name: 'Sujay',
        email: 'sujay.tripathy@gmail.com',
        role : 'Engineer',
        website : '',
        about : 'N/A',
        img:"/images/members/blank.PNG"
    },
    {
        id: 14,
        name: 'Srivathsan (Sibi / CB)',
        email: 'srivathsan.prakash@gmail.com',
        role : 'Engineer',
        website : '',
        about : 'N/A',
        img:"/images/members/sibi.jpg"
    },

]
export default MembersData;