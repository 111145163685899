import React from "react";
import { Container, Row } from "react-bootstrap";
import MemberItem from "./MemberItem";
import MembersData from "../../Data/MembersData";
import "../../css/App.css";

export default function NestedGrid() {
  const MemberDataComponents = MembersData.map((item) => (
    <MemberItem
      id={item.id}
      name={item.name}
      email={item.email}
      role={item.role}
      website={item.website}
      about={item.about}
      image={item.img}
    />
  ));

  return (
    <div className="App">
      <br />
      <Container>
        <Row>{MemberDataComponents}</Row>
      </Container>
    </div>
  );
}
