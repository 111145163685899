
const refImageData = [
    {
        img:"/images/killSteel_screenshots/11.png",
        title:"Car Image",
    },
    {
        img:"/images/killSteel_screenshots/22.png",
        title:"Car Image",
    },
    {
        img:"/images/killSteel_screenshots/33.png",
        title:"Car Image",
    },
    {
        img:"/images/killSteel_screenshots/44.png",
        title:"Car Image",
    },
    {
        img:"/images/killSteel_screenshots/55.png",
        title:"Car Image",
    },
    {
        img:"/images/killSteel_screenshots/66.png",
        title:"Car Image",
    },
    {
        img:"/images/killSteel_screenshots/77.png",
        title:"Car Image",
    },
    {
        img:"/images/killSteel_screenshots/88.png",
        title:"Car Image",
    },
    {
        img:"/images/killSteel_screenshots/99.png",
        title:"Car Image",
    },
    {
        img:"/images/killSteel_screenshots/10.png",
        title:"Car Image",
    },
    // {
    //     img:"/images/killSteel_screenshots/11.png",
    //     title:"Car Image",
    // },
    // {
    //     img:"/images/killSteel_screenshots/12.png",
    //     title:"Car Image",
    // },
    // {
    //     img:"/images/killSteel_screenshots/13.png",
    //     title:"Car Image",
    // },
    // {
    //     img:"/images/killSteel_screenshots/14.png",
    //     title:"Car Image",
    // },
]

export default refImageData;