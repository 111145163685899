import React from 'react';
import {Container,Jumbotron } from 'react-bootstrap'

const Download = () => (
    <Container>
        <br/>
        <Jumbotron>
            You have subscribed to killSteel
            {/* <h1>Download Build for version 3.23 (Windows)</h1> */}
                {/* <p>
                    Player drives around and shoot, slam and kill, other cars with power up
                </p> */}
                {/* <p>
                    <button>
                      <a href="https://store.steampowered.com/app/1269550/KillSteel/">Play game on steam</a>
                    </button>
                </p> */}
        </Jumbotron>
    </Container>
);

export default Download;