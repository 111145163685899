import React from 'react';
import {Container} from 'react-bootstrap'
import { Timeline, Follow, Hashtag, Share } from 'react-twitter-widgets'
const News = () => {
  return (
    <div>
        <br/>
        <Container>
            <Follow username="killsteelgame"/>
            <Share url='https://twitter.com/killsteelgame'/>
            <Hashtag hashtag="killsteelgame"/>
            <Timeline
            dataSource={{
            sourceType: 'profile',
            screenName: 'killsteelgame'
            }}
            options={{
            username: 'killsteelgame',
            height: '500',
            }}
            onLoad={() => console.log('Timeline is loaded!')}
            />
            
        </Container>
    </div>
)
}


export default News;