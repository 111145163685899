import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Col from 'react-bootstrap/Col'

const useStyles = makeStyles({
    card: {
      maxWidth: 345,
    },
    media: {
      height: 300,
    },
    P_bottom :{
        paddingBottom : 15,
    },
  });
const MemberItem = (props)=>{
    const classes = useStyles();
    return (
    <Col sm={4} md={6} lg={4} className={classes.P_bottom}>
        <Card className={classes.card}>
        {/* <CardActionArea> */}
            <CardMedia
            className={classes.media}
            image={props.image}
            title="Contemplative Reptile"
            />
            <CardContent>
            <h6>Name : {props.name}</h6>
            <h6>Contact : {props.email}</h6>
            {/* <h6>Role : {props.role}</h6> */}
              {/* This is the about modal */}
                {/* <MyModal name={props.name} about={props.about}/> */}
              {/* This is the about */}
            </CardContent>
        
        </Card>
    </Col>
    )
}
export default MemberItem;





