import React from 'react';
import { Link } from 'react-router-dom';
import {Navbar, Nav, Button, Image} from 'react-bootstrap'

import { AuthUserContext } from '../Session';
// import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <NavigationAuth authUser={authUser} />
      ) : (
        <NavigationNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => (
  <div>
 <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
 <Link to={ROUTES.LANDING}><Navbar.Brand><Image src="../images/logoes/chickenchuckin_logo.png" height="70px"/></Navbar.Brand></Link>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
    {/* <Link to={ROUTES.ABOUT}><Nav.Link href={ROUTES.ABOUT}>About</Nav.Link></Link> */}
     <Link to={ROUTES.MEDIA}><Nav.Link href={ROUTES.MEDIA}>Screenshots</Nav.Link></Link>
     <Link to={ROUTES.NEWS}><Nav.Link href={ROUTES.NEWS}>News</Nav.Link></Link>
     <Link to={ROUTES.MEETOURTEAM}><Nav.Link href={ROUTES.MEETOURTEAM}>Meet Our Team</Nav.Link></Link>    
    </Nav>
  </Navbar.Collapse>
</Navbar>
</div>
);

const NavigationNonAuth = () => (
  <div>
    <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
    <Link to={ROUTES.LANDING}><Navbar.Brand><Image src="../images/logoes/chickenchuckin_logo.png" height="70px"/></Navbar.Brand></Link>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
        <Link to={ROUTES.MEDIA}><Nav.Link href={ROUTES.MEDIA}>Screenshots</Nav.Link></Link>
        <Link to={ROUTES.NEWS}><Nav.Link href={ROUTES.NEWS}>News</Nav.Link></Link>
        <Link to={ROUTES.MEETOURTEAM}><Nav.Link href={ROUTES.MEETOURTEAM}>Meet Our Team</Nav.Link></Link>   
        </Nav>
        <Nav>
        <Link to={ROUTES.SIGN_IN}><Button>Subscribe</Button></Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </div>
);

export default Navigation;
