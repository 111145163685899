import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import RefImagesData from '../../Data/RefImagesData';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: "url(/images/killSteel_screenshots/66.png)",
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    
  },
  gridList: {
    width: 1000,
    height: 800,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

export default function TitlebarGridList() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <GridList cellHeight={200} className={classes.gridList}>
        <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
          {/* <ListSubheader component="div">Screenshots</ListSubheader> */}
        </GridListTile>
        {RefImagesData.map(tile => (
          <GridListTile key={tile.img+tile.title}>
            <img src={tile.img} alt={tile.title} />
            {/* <GridListTileBar
              title={tile.title}
            /> */}
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}
